/* ======================================================== */

main.main{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  background-color: #1C1C20;
  color: white;

  overflow: hidden;
  position:absolute;
  overflow-y:hidden;
  top:0;
  bottom:0;
}

div.library {
  margin-left: 220px;
  width: calc(100vw - 220px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}


section.library {
  max-width: 600px;
  min-width: 300px;
  /* width: calc(100vw - 240px); */
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: #26262F;
  border-radius: 10px;
  margin: 0px 0px 20px 20px;

}


div.library-head {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: #8F8FA3;
}

div.library-head svg{
  margin-left: 20px;
  height: 30px;
}

h1.library-head {
  margin-left: 18px;
}

div.library-main {
  display: flex;

}




/* ======================================================== */




section.shelf{
  height: calc(100vh - 50px);
  width: 500px;
  position: relative;
}

@media only screen and (max-width: 768px) {

  section.shelf:not(:first-child) {
    display: none;
  }

}

#after:after {
  content: "";
  background: #65657B;
  border-radius: .25px;
  position: absolute;
  top: 2%;
  right: 0;
  height: 95%;
  width: .5px;
}


div.shelf-head {
  margin: 6px 0px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1.shelf-head{
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
}

div.shelf-main{
  width: 100%;
  height: calc(100vh - 152px);
  display: flex;
  justify-content: center;
}


div.shelf-fade{
  width: 92%;
  height: calc(100vh - 152px);
  pointer-events: none;
  position: absolute;
  background: rgb(50,50,61);
  background: linear-gradient(0deg, rgba(38,38,47,1) 0%, rgba(38,38,47,0) 6%, rgba(38,38,47,0) 98%, rgba(38,38,47,1) 100%);
}

div.shelf-list{
  width: 96%;
  padding-top: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}




/* ======================================================== */




div.book-cover {
  width: 90%;
  padding: 2px;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: background-color 170ms ease-in 30ms;
  animation: .15s blurIn;
  animation-timing-function: ease-in;
}

div.book-cover:hover {
  background-color: #5C5C70;
}


div.book {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}

div.book svg {
  width: 22px;
  height: 22px;
  margin: 2px;
}


div.book-info {
  width: calc(100% - 22px);
  overflow-x: hidden;
  overflow-y: hidden;
  letter-spacing: 1px;
  margin-left: 10px;

}

div.book-title{
  width: 400px;
  margin-right: 0px;
  margin-bottom: 7px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  height: 1em;
  font-size: 11px;
  color: white;

}

div.book-id{
  color: #B0B0BF;
  font-size: 11px;
  overflow-x: hidden;
  overflow-y: hidden;
}
