@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700&display=swap);

section.me {
  width: 220px;
  display: flex;
  justify-content: center;
  top: 0px;
  left: 0px;
  position: fixed;
}

div.me {
  width: 200px;
  height: calc(100vh - 40px);
  min-height: 600px;
  margin: 20px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #26262F;
  border-radius: 10px;
}

div.logo {
  width: 100%;
  display: flex;
}


div.logo a {
  outline: none;
  border: none;
}

div.logo svg {
  margin: 10px 0px 0px 10px;
  width: 20px;
}

/* h1.logo {
  font-size: 10px;
  color: #8F8FA3;
} */


div.me > svg{
  padding: 15px;
  width: 20%;
}

.blurr {
  animation: 400 blurIn;
  animation-timing-function: ease-in;
}

h1.me{
  font-size: 10px;
  height: 10px;
  color: #D2D2DA;
  margin-bottom: 10px;

}

div.me-status{
  width: 100%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

}

div.me-status > svg{
  width: 12px;
  margin: 0px 10px;

}

h3.me-status{
  font-size: 10px;
  height: 12px;
  letter-spacing: 1px;
  color: #A5A5B6;
  vertical-align: middle;
  margin-right: 10px;
}

section.my{
  width: 100%;
  min-height: 200px;
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


div.my {
  width: 80%;
  height: .5px;
  margin: 10px 0px;
  background-color: #65657B;
}

div.create {
  padding: 5px 10px;
  height: 20px;
  margin-top: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 170ms ease-in 30ms;
  cursor: pointer;
}

div.create:hover {
  background-color: #5C5C70;
}

div.create > svg {
  pointer-events: none;
  height: 80%;

}

p.create {
  pointer-events: none;
  margin-left: 10px;
  /* padding-top: .5px; */
  font-size: 12px;
  font-weight: 400;

}


div.my-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}




/* ======================================================== */







section.my div.book-cover {
  width: 90%;
  padding: 2px;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: background-color 170ms ease-in 30ms;
  animation: .15s blurIn;
  animation-timing-function: ease-in;
}



section.my  div.book svg {
  width: 18px;
  height: 18px;
  margin: 2px;
}


section.my  div.book-info {
  width: calc(100% - 22px);
}

section.my  div.book-title{
  width: 100%;
}

section.my  div.book-id{
  width: 90%;
  font-size: 9px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}


@keyframes blurIn {
  0% {
    opacity: 0;
    filter: blur(3px);
  }
  50% {
    filter: blur(3px);
  }
  100% {
    opacity: 1;

    filter: blur(0px);
  }
}

.fill-white {
  fill: #FFFFFF;
}

.stroke-white {
  stroke: #FFFFFF;
}

.stroke-round {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 8px;
}


.fill-red{
  fill: #FB7777;
}

.stroke-red{
  stroke: #FB7777;
}

.fill-none{
  fill: none;
}



.fill-area{
  width: 100%;
  height: 100%;
}

.browseSVG {
  stroke: #FFF;
  stroke-width: 20px;
  fill: #FFF;
  fill-opacity: .1;
}



svg.doc_symbol .a {
  fill: #7A7B7F;
}

svg.doc_symbol .b {
  fill: #FFFFFF;
}

/* ======================================================== */

main.main{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  background-color: #1C1C20;
  color: white;

  overflow: hidden;
  position:absolute;
  overflow-y:hidden;
  top:0;
  bottom:0;
}

div.library {
  margin-left: 220px;
  width: calc(100vw - 220px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}


section.library {
  max-width: 600px;
  min-width: 300px;
  /* width: calc(100vw - 240px); */
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: #26262F;
  border-radius: 10px;
  margin: 0px 0px 20px 20px;

}


div.library-head {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: #8F8FA3;
}

div.library-head svg{
  margin-left: 20px;
  height: 30px;
}

h1.library-head {
  margin-left: 18px;
}

div.library-main {
  display: flex;

}




/* ======================================================== */




section.shelf{
  height: calc(100vh - 50px);
  width: 500px;
  position: relative;
}

@media only screen and (max-width: 768px) {

  section.shelf:not(:first-child) {
    display: none;
  }

}

#after:after {
  content: "";
  background: #65657B;
  border-radius: .25px;
  position: absolute;
  top: 2%;
  right: 0;
  height: 95%;
  width: .5px;
}


div.shelf-head {
  margin: 6px 0px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1.shelf-head{
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
}

div.shelf-main{
  width: 100%;
  height: calc(100vh - 152px);
  display: flex;
  justify-content: center;
}


div.shelf-fade{
  width: 92%;
  height: calc(100vh - 152px);
  pointer-events: none;
  position: absolute;
  background: rgb(50,50,61);
  background: linear-gradient(0deg, rgba(38,38,47,1) 0%, rgba(38,38,47,0) 6%, rgba(38,38,47,0) 98%, rgba(38,38,47,1) 100%);
}

div.shelf-list{
  width: 96%;
  padding-top: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}




/* ======================================================== */




div.book-cover {
  width: 90%;
  padding: 2px;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: background-color 170ms ease-in 30ms;
  animation: .15s blurIn;
  animation-timing-function: ease-in;
}

div.book-cover:hover {
  background-color: #5C5C70;
}


div.book {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}

div.book svg {
  width: 22px;
  height: 22px;
  margin: 2px;
}


div.book-info {
  width: calc(100% - 22px);
  overflow-x: hidden;
  overflow-y: hidden;
  letter-spacing: 1px;
  margin-left: 10px;

}

div.book-title{
  width: 400px;
  margin-right: 0px;
  margin-bottom: 7px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  height: 1em;
  font-size: 11px;
  color: white;

}

div.book-id{
  color: #B0B0BF;
  font-size: 11px;
  overflow-x: hidden;
  overflow-y: hidden;
}

header.plan {
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 50px;
  border-bottom: 1px solid #787891;
  display: flex;
  justify-content: flex-start;
  background-color: #2A2A33;
  z-index: 2;

}

header.plan > svg{
  height: 34px;
  padding: 8px 0px;
  width: 54px;
}




footer.plan {
  width: 100vw;
  height: 50px;
  background-color: #F9f9f9;
  position: relative;
  display: flex;
}


section.search {
  min-height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  padding: 0px;
}


form.search input[type='text'] {
  max-width: 600px;
  width: calc(100vw - 240px);
  height: 60px;
  padding: 0px 20px;
  margin-left: 20px;
  border-radius: 10px;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  vertical-align: bottom;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.6px;
  background: none;
  background-color: #49495A;
  color: white;
}

div.loading {
  width: 100vw;
  height: calc(100vh - 50px);
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

}

div.loading > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75px;

}


div.loading > div > div {
  display: block;
  margin-bottom: 6px;
  height: 6px;
  background-color: #FFF;
  width: 10px;
}

/* ======================================================== */
main.desk {
  width: calc(100vw - 220px);
  margin-left: 220px;
  max-height: 100vh !important;
  position: fixed;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: white;

}

body{
  position: fixed;
  max-width: 100%;
  max-height: 100%;
}

div.desk {
  width: calc(100vw - 260px);
  height: calc(100vh - 40px);
  margin: 20px;
  background-color: #26262F;

  border-radius: 10px;
  transition: opacity 1000ms;
}

/* ======================================================== */
div.desk-head {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

div.desk-head svg {
  height: 18px;
  flex-shrink: 0;
  display: inline-block;
  padding: 15px 0px 15px 15px;
}

div.desk-head-title {
  height: 1em;
  width: calc(100% - 55px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  color: #B0B0BF;
  padding-right: 10px;
  padding-left: 10px;
  letter-spacing: 1.4px;
}

div.desk-main {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
}

section.list {
  min-width: 180px;
  width: 180px;
  height: 100%;
  position: relative;
  margin-right: 0px;
}

/* section.list:nth-child(1):after {
  width: .5px;
  height: 95%;
  position: absolute;
  top: 2%;
  right: 0;
  content: "";
  background: #65657B;
} */

div.list-head {
  width: 100%;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1.list-head {
  padding: 0px 15px;
  font-weight: 500;
  font-size: 12.5px;
  text-align: center;
}

div.list-head svg {
  width: 16px;
  margin-left: 5px;
}

div.list-main {
  width: 100%;
  height: calc(100vh - 152px);
  display: flex;
  justify-content: center;
}

div.list-fade {
  width: 92%;
  height: calc(100vh - 152px);
  position: absolute;
  pointer-events: none;
  z-index: 2;
  background: rgb(50, 50, 61);
  background: linear-gradient(0deg, rgba(38,38,47, 1) 0%, rgba(38,38,47, 0) 6%, rgba(38,38,47, 0) 98%, rgba(38,38,47, 1) 100%);
}

div.list {
  width: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

div.list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img.list-img {
  width: 135px;
  height: 65px;
  pointer-events: none;
  object-fit: cover;
  animation: .15s blurIn;
  animation-timing-function: ease-in;
}

img.list-img-drag {
  height: 25px;
}

div.list-info {
  width: 160px;
  height: 20px;
  padding: 4px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
  text-transform: capitalize;
}

h2.list-info {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}

div.list-bookend {
  width: 70%;
  min-height: .5px;
  background-color: #65657B;
}

div.list-bookend:nth-child(1) {
  margin: 10px 0px 25px 0px;
}

div.list-bookend:nth-child(2) {
  margin: 25px 0px 10px 0px;
}

div.list-space {
  min-height: 50px;
  width: 100%;
}

p.list-placeholder {
  font-size: 12px;
  color: #C7C7D1;
  margin: 5px 0px 30px 0px;
}

/* ======================================================== */
form.new {
  width: 100%;
  background-color: #34343F;
  display: flex;
  border-radius: 10px;
}

form.new input[type='text'] {
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.2px;
  border: none;
  outline: none;
  vertical-align: bottom;
  background-color: #34343F;
  color: white;
}

/* ======================================================== */
section.view {
  flex-grow: 1;
  height: 100%;
}

section.view > div{
  margin-right: 1px;
  margin-bottom: 1px;
  border-radius: 10px;
  border: solid 1px #47475C;
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  /* background-color: #2B2B34; */
}

div.view-head {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 5px 0px;
}

div.view-title {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.view-bar {
  width: calc(100% - 20px);
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #34343F;
  margin: 0px 10px;
}

h3.view-title {
  padding: 0px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #DDDDE3;

}

h4.view-title {
  padding: 0px 15px;
  font-size: 11px;
  font-weight: 400;
  color: #C7C7D1;

}

div.view-main {
  height: calc(100% - 67px);
  width: 100%;
  cursor: default;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

div.view-img {
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  margin: 0px auto;
  /* overflow: scroll; */

}

div.view-img canvas {
  filter: brightness(1.3);
}

/* div.view-img img {
  filter: brightness(1.3);
  display: block;
  margin: 0px auto;
  max-width: 100%;
} */

div.view-text {
  width: 100%;
  height: 220px;
  min-height: 220px;
  overflow: scroll;
  color: white;
  font-family: "Whitney Cond A", "Whitney Cond B";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6em;
  display: flex;
  justify-content: center;
}


div.view-text-field {
  /* width: calc(100vw - 441px); */

  height: 205px;
  min-height: 205px;
  width: calc(100% - 20px);
  border-radius: 10px;
  background-color: #34343F;
  margin: 5px 10px 10px 10px;
  overflow-y: scroll;
  padding: 0px 15px;
  text-transform: none;
}

div.view-text-fade {
  width: calc(100vw - 441px);
  height: 100px);
  min-height: 150px;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  background: rgb(50, 50, 61);
  background: linear-gradient(0deg, rgba(38,38,47, 1) 0%, rgba(38,38,47, 0) 0%, rgba(38,38,47, 0) 90%, rgba(38,38,47, 1) 97%);
}

/* ======================================================== */
div.acts {
  width: 90%;
  margin: 20px auto 0px auto;
}

div.act-head {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 11px;
}

div.act-main {
  height: 35px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  background-color: #34343F;
}

form.act {
  width: 100%;
  border-radius: 10px;
  display: flex;
  background-color: #34343F;
}

form.act input[type='text'] {
  width: calc(100% - 50px);
  margin-left: 15px;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  vertical-align: bottom;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.4px;
  background-color: #34343F;
  transition: all 250ms ease-in;
  color: white;
}

form.act input[type='submit'] {
  display: none;
  background-color: #34343F;
}

label.act {
  height: 35px;
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
  outline: none;
  border: none;
  background: none;
  background-color: #49495A;
}

label.act>svg {
  height: 25px;
  padding: 5px 10px;
  pointer-events: none;
}

/* ======================================================== */




form.archtitle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

}

form.archtitle input[type='text']:-ms-input-placeholder  {
  color: #C7C7D1;

}

form.archtitle input[type='text']::placeholder  {
  color: #C7C7D1;

}

form.archtitle input[type='text'] {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: none;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  text-transform: uppercase;
  transition: all 400ms ease-out 10ms;
  height: 1em;
  width: calc(100% - 55px);
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 20px;
}

form.addto {
  height: 32px;
  display: flex;
  align-items: center;

}

div.addto {
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 10px;
  border-radius: 10px;
  background-color: #34343F;
  transition: all 100ms ease-out 10ms;

}

div.addto:hover{
  background-color: #5C5C70;
}


div.addtobutton {
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 1px;
}



div.addtobutton > svg{
  height: 16px;
  width: 16px;
}

div.addtobutton > p{
  margin-left: 5px;
  color: #C7C7D1;
}

div.drop{
  height: 100%;
  align-self: flex-start;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  margin: 0px 5px;

}

div.droptop{
  position: relative;
  margin-bottom: .2px;
}


div.droptop input.id {
  display: none;
}

div.droptop input.title {
  width: 80px;
  height: 1em;
  -webkit-appearance: none;
          appearance: none;
  border: none;
  outline: none;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  letter-spacing: 1.6px;
  font-size: 12px;

  font-weight: 500;
  text-transform: uppercase;
  color: white;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}


div.droptop svg {
  width: 12px;
}

div.dropdown {
  height: 14px;
}

div.dropdown > div{
  display: inline-block;
  padding: 0px 10px;
  color: #C7C7D1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  letter-spacing: 1.2px;
  font-size: 11px;
  opacity: 0;
  transition: letter-spacing 200ms ease-in 20ms;

}

div.dropdown > div:hover{
  letter-spacing: 1.8px;
  color: #E9E9ED;
}


form.addto > svg {
  width: 14px;
  cursor: pointer;
  padding: 5px;
}

form.addtag {
  width: 120px;

  border-radius: 10px;
  display: flex;
  height: 100%;
  margin-left: 15px;
  padding: 0px;
}

form.addtag input[type='text'] {
  height: 100%;
  width: 120px;
  padding: 5px 12px;
  text-align: right;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  vertical-align: bottom;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.4px;
  background-color: #34343F;
  transition: all 250ms ease-in;
  color: white;
  border-radius: 10px;

}

/* form.addtag input[type='text']:focus {
  background-color: #49495A;
} */

form.addtag input[type='text']:hover {
  letter-spacing: 2px;

}


header.curtain {
  width: 350px;
  height: 100vh;
  border-right: solid 1px black;
  border-bottom: none;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
  background-color: #FFF;
}



.link {
  font-weight: 500;
  color: #000;
  text-decoration: underline;
}


header.curtain div.logo {
  display: block;
  width: 50px;
  height: 80vh;
  margin-left: 50px;
  overflow: hidden;
}

div.logo > div {
  display: block;
  margin-bottom: 4px;
  height: 4px;
  background-color: #3975EB;
  margin-left: 0px;
  width: 50px;
}


div.logo > div:nth-child(-n+5) {
  background-color: #3975EB;

}


div.nav-box{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

nav.lobby {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  text-decoration: none;
}

nav.lobby button {
  border: none;
  background: none;
  font-family: 'Oswald', sans-serif;
  padding: 0;
  letter-spacing: 0px;
  transition: letter-spacing 300ms ease-out 10ms;
  text-transform: uppercase;
  font-size: 30pt;
  font-weight: 600;
  opacity: 0;
  color: #000;
  margin: 0px;
  text-align: left;
}


.swipe {
  width: 350px;
  height: 100vh;
  position: absolute;
  background: rgb(57,117,235);
  background: linear-gradient(164deg, rgba(57,117,235,1) 0%, rgba(38,88,187,1) 100%);
  z-index: 2;
}


.access:focus {
  outline: none;
  border: 2px solid #061F40;
  letter-spacing: 3px;
}




nav.lobby a:nth-child(-n + 2) button {
  color: #666;
}

nav.lobby button:hover {
  letter-spacing: 3px;
}

nav.lobby > a > button:focus{
  outline: none;
  border: 3px solid #061F40;
  letter-spacing: 3px;
  box-shadow: inset 2px 2px 4px #cfcfcf,
              inset -2px -2px 4px #ffffff;
}

main *:focus{
  outline: none;
  border: 3px solid #061F40;
}

body {
  margin: 0;
}

div.curtain {
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}


main.about, main.home  {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Roboto Slab', serif;
  color: #333;
}

@media screen and (min-width: 900px) {
  main.about, main.home  {
    max-width: 800px;
    width: calc(100vw - 350px);
    min-height: 100vh;
    left: 350px;
  }
  main.home {
    justify-content: center;
  }
}


main.about p {
  padding: 0px 40px;
  font-size: 14pt;
}


.logosvg1 {
  height: 23pt;
  margin-bottom: -2px;
  margin-right: 4px;
}

.logosvg2 {
  height: 14pt;
  margin-bottom: -2px;
  margin-right: 2px;
}

main.about > svg {
  padding: 0px 60px 0px 40px;
}



main.about .contact{
  text-transform: uppercase;
  padding: 40px;
  font-weight: 700;
  font-size: 30pt;
}

main.about .contact > a {
  color: #000;
  transition: all 300ms ease-out 10ms;
}

main.about .contact > a:hover {
  letter-spacing: 2px;
  color: #1977f3;
}

svg.logo {
  padding: 0px;
  width: 100px;
  align-self: center;
}

main.home h1, main.home h1 {
  padding: 0px 40px;
  text-align: center;
}

span.logo {
  font-family: 'Arimo', sans-serif;
  color: #000;
  font-weight: 700;
  text-decoration: none;
}



footer.about {
  margin-top: 80px;
  border-top: 1px solid #CCC;

  width: 100%;
  height: 200px;
  margin-top: 80px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

footer.about > div {
  width: 50%;
  display: flex;
  justify-content: center;
}


footer.about a {

}

footer.about .pitl a {
  width: 60%;
}

footer.about .dpl a {
  width: 75%;
}

footer.about .tasc a {
  width: 72%;
}

footer.about .shc a {
  width: 85%;
}

footer.about a:hover {
  opacity: .6;

}

footer.about svg{
  width: inherit;
}

footer.about img {
  width: inherit;
}





.fade-exit main{
  opacity: 1;
}

.fade-exit-active main{
  opacity: 0;
  transition: opacity 1ms ease-in 700ms;
}

.fade-enter main{
  opacity: 0;
}

.fade-enter-active main{
  opacity: 1;
  transition: opacity 1ms ease-in 701ms;
}

.fade-enter .swipe{
  animation-timing-function:  cubic-bezier(0.5 0.0, 0.5, 1.0);
  animation: swipe 1400ms;
}

@keyframes swipe {
  0% {
    width: 350px;
  }
  50% {
    width: 100vw;
  }
  100% {
    width: 350px;
  }
}



@media screen and (max-width: 900px) {



  main.home, main.about {
    top: 100px;
    width: 100vw;
    max-width: 100vw;
    min-height: calc(100vh - 100px);
    left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  div.curtain {
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  header.curtain {
    width: 100vw;
    height: 100px;
    border-bottom: solid 1px black;
    border-right: none;
    justify-content: center;
    align-items: center;
  }

  div.nav-box{
    margin-left: 0px;
  }

  header.curtain div.logo {
    display: none;
  }

  header.curtain div.logo > div {
    display: none;
  }

  nav.lobby {
    flex-direction: row;
    justify-content: center;
  }

  nav.lobby button {
    margin: 10px;
    font-size: 4vw;
  }





  svg.logo {
    padding: 0px;
    width: 10vw;
  }


  .swipe {
    top: 0;
    height: 100px;
    width: 100vw;
  }


  .fade-exit main{
    opacity: 1;
  }

  .fade-exit-active main{
    opacity: 0;
    transition: opacity 1ms ease-in 700ms;
  }

  .fade-enter main{
    opacity: 0;
  }

  .fade-enter-active main{
    opacity: 1;
    transition: opacity 1ms ease-in 701ms;
  }

  .fade-enter .swipe{
    animation-timing-function:  cubic-bezier(0.5 0.0, 0.5, 1.0);
    animation: smallswipe 1400ms;
  }

}

@media screen and (max-width: 500px) {

  /* h1 {
    padding: 30px;
    font-weight: 400;
    font-size: 5vw;
    line-height: 7vw;
  } */


  nav.lobby button {
    margin: 10px;
    font-size: 6vw;
  }



  svglogo {
    padding: 0px;
    width: 25vw;
  }





}

main.about, main.home  {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Roboto Slab', serif;
  color: #333;
}

@media screen and (min-width: 900px) {
  main.about, main.home  {
    max-width: 800px;
    width: calc(100vw - 350px);
    min-height: 100vh;
    left: 350px;
  }
}


.about p {
  padding: 0px 40px;
  font-size: 14pt;
}


.logosvg1 {
  height: 23pt;
  margin-bottom: -2px;
  margin-right: 4px;
}

.logosvg2 {
  height: 14pt;
  margin-bottom: -2px;
  margin-right: 2px;
}

main.about > svg {
  padding: 0px 60px 0px 40px;
}

main.home {
  justify-content: center;
}

main.about .contact{
  text-transform: uppercase;
  padding: 40px;
  font-weight: 700;
  font-size: 30pt;
}

main.about .contact > a {
  color: #000;
  transition: all 300ms ease-out 10ms;
}

main.about .contact > a:hover {
  letter-spacing: 2px;
  color: #1977f3;
}

svg.logo {
  padding: 0px;
  width: 100px;
  align-self: center;
}

main.home h1, main.home h1 {
  padding: 0px 40px;
  text-align: center;
}

span.logo {
  font-family: 'Arimo', sans-serif;
  color: #000;
  font-weight: 700;
  text-decoration: none;
}



footer.about {
  margin-top: 80px;
  border-top: 1px solid #CCC;

  width: 100%;
  height: 200px;
  margin-top: 80px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

footer.about > div {
  width: 50%;
  display: flex;
  justify-content: center;
}


footer.about a {

}

footer.about .pitl a {
  width: 60%;
}

footer.about .dpl a {
  width: 75%;
}

footer.about .tasc a {
  width: 72%;
}

footer.about .shc a {
  width: 85%;
}

footer.about a:hover {
  opacity: .6;

}

footer.about svg{
  width: inherit;
}

footer.about img {
  width: inherit;
}

