section.me {
  width: 220px;
  display: flex;
  justify-content: center;
  top: 0px;
  left: 0px;
  position: fixed;
}

div.me {
  width: 200px;
  height: calc(100vh - 40px);
  min-height: 600px;
  margin: 20px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #26262F;
  border-radius: 10px;
}

div.logo {
  width: 100%;
  display: flex;
}


div.logo a {
  outline: none;
  border: none;
}

div.logo svg {
  margin: 10px 0px 0px 10px;
  width: 20px;
}

/* h1.logo {
  font-size: 10px;
  color: #8F8FA3;
} */


div.me > svg{
  padding: 15px;
  width: 20%;
}

.blurr {
  animation: 400 blurIn;
  animation-timing-function: ease-in;
}

h1.me{
  font-size: 10px;
  height: 10px;
  color: #D2D2DA;
  margin-bottom: 10px;

}

div.me-status{
  width: 100%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

}

div.me-status > svg{
  width: 12px;
  margin: 0px 10px;

}

h3.me-status{
  font-size: 10px;
  height: 12px;
  letter-spacing: 1px;
  color: #A5A5B6;
  vertical-align: middle;
  margin-right: 10px;
}

section.my{
  width: 100%;
  min-height: 200px;
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


div.my {
  width: 80%;
  height: .5px;
  margin: 10px 0px;
  background-color: #65657B;
}

div.create {
  padding: 5px 10px;
  height: 20px;
  margin-top: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 170ms ease-in 30ms;
  cursor: pointer;
}

div.create:hover {
  background-color: #5C5C70;
}

div.create > svg {
  pointer-events: none;
  height: 80%;

}

p.create {
  pointer-events: none;
  margin-left: 10px;
  /* padding-top: .5px; */
  font-size: 12px;
  font-weight: 400;

}


div.my-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}




/* ======================================================== */







section.my div.book-cover {
  width: 90%;
  padding: 2px;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: background-color 170ms ease-in 30ms;
  animation: .15s blurIn;
  animation-timing-function: ease-in;
}



section.my  div.book svg {
  width: 18px;
  height: 18px;
  margin: 2px;
}


section.my  div.book-info {
  width: calc(100% - 22px);
}

section.my  div.book-title{
  width: 100%;
}

section.my  div.book-id{
  width: 90%;
  font-size: 9px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}
