header.plan {
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 50px;
  border-bottom: 1px solid #787891;
  display: flex;
  justify-content: flex-start;
  background-color: #2A2A33;
  z-index: 2;

}

header.plan > svg{
  height: 34px;
  padding: 8px 0px;
  width: 54px;
}




footer.plan {
  width: 100vw;
  height: 50px;
  background-color: #F9f9f9;
  position: relative;
  display: flex;
}
